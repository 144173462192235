$( document ).ready(function() {

    $(window).on('resize', setTransitionToMenu);



    $('#navBurger').on('click', openMenu);
    $('#navCancel').on('click', closeMenu);

    function openMenu() {
        $('#menu').addClass('-open');
    }

    function closeMenu() {
        $('#menu').removeClass('-open');
    }

    function setTransitionToMenu(delay) {
        var match = window.matchMedia( "(max-width: 770px)" );
        if (match.matches) {
            $('#menu').addClass('-transition');
        } else {
            $('#menu').removeClass('-transition');
            closeMenu();
        }
    }

    setTransitionToMenu();


});